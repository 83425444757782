/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// prettier-ignore

import { TiArrowBackOutline } from 'react-icons/ti';
import { IoCashOutline } from 'react-icons/io5';
import React, { useEffect, useState } from 'react';

import { CardHeader, Container, HeaderItemText, HeaderRight, HeaderRightItem, Left, PointedDivisor, Profile, ProfileImage, ProfileInfo, ProfileName, ProfileSince, Route, RouteAddress, RouteHeader, RouteItem, RouteItem2, RouteList, Seats, TripTotalValue, TripValues, TripValuesContainer, RouteInfoValues } from './styles';
import PassengerImage from '../../assets/images/PassengerProfileImage2.jpg';
import PointedDivisorImg from '../../assets/images/pointedDivisor.svg';
import Divisor from '../../assets/images/divisor.svg';

export default function NCardTripDriver({
  adress1,
  adress2,
  price,
  time,
  isDriver,
  seats,
  passengers,
  trip,
  driverLat,
  driverLng,
  ...rest
}) {
  const [timeTo, setTime] = useState('');
  const [distanceTo, setDistance] = useState('');
  const getPassengerCost = passengerId => {
    const data = trip.cost.costPerPassenger.find(
      passenger => passenger.passengerId === passengerId
    );

    return data.cost;
  };

  function getCreatedDate(date) {
    const createdAt = new Date(date);
    return createdAt.toLocaleDateString('pt-BR');
  }

  function getTripDistance() {
    const waypoints = [];
    trip.passengers.map((passenger, index) => (
      index === 0 ? waypoints.push({ from: { lng: passenger.boardingAddress.longitude, lat: passenger.boardingAddress.latitude }, to: { lng: passenger.landingAddress.longitude, lat: passenger.landingAddress.latitude } }) : waypoints.push({ from: {}, to: { lng: passenger.landingAddress.longitude, lat: passenger.landingAddress.latitude } })
    ))

    const from = waypoints.map((waypoint, index) => (
      index === 0 ? { lng: waypoint.from.lng, lat: waypoint.from.lat } : ''
    ))
    const fromLocation = `${from[0].lng},${from[0].lat};`

    const to = waypoints.map((waypoint) => (
      waypoint.to
    ))
    const toPoints = to.map((point) => (
      `${point.lng}, ${point.lat};`
    ))
    const toLocation = toPoints.join(" ");

    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLocation}${(toLocation.slice(0, toLocation.length - 1))}?access_token=${process.env.REACT_APP_MAP_BOX_API_KEY}`;

    try {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const durationInSeconds = data.routes[0].duration;
          const durationInMinutes = (durationInSeconds / 60).toFixed(0);
          const durationInHours = (durationInMinutes / 60)
            .toFixed(2)
            .toString()
            .split('.');
          const parsedDurationInHours = 60 / (100 / Number(durationInHours[1]));
          setTime(
            durationInMinutes <= 59
              ? `${durationInMinutes}min`
              : `${durationInHours[0]}:${parsedDurationInHours.toFixed(0).length === 1
                ? `0${parsedDurationInHours.toFixed(0)}`
                : parsedDurationInHours.toFixed(0)
              }min`
          );

          const distanceInMeters = data.routes[0].distance;
          const distanceInKilometers = (distanceInMeters / 1000).toFixed(2);
          setDistance(
            distanceInMeters <= 999
              ? `${distanceInMeters}metros`
              : `${distanceInKilometers}kms`
          )
        })
        .catch(err => {
          console.error(err);
        });
    } catch {
      setTime('Aconteceu um erro');
    }
  }

  const fetchTimePassenger = (to, from) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${to.lng},${to.lat};${from.lng},${from.lat}?access_token=${process.env.REACT_APP_MAP_BOX_API_KEY}`;
    const [passengerTime, setPassengerTime] = useState('');
    const [passengerDistance, setPassengerDistance] = useState('');
    try {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const durationInSeconds = data.routes[0].duration;
          const durationInMinutes = (durationInSeconds / 60).toFixed(0);
          const durationInHours = (durationInMinutes / 60)
            .toFixed(2)
            .toString()
            .split('.');
          const parsedDurationInHours = 60 / (100 / Number(durationInHours[1]));
          setPassengerTime(
            durationInMinutes <= 59
              ? `${durationInMinutes}min`
              : `${durationInHours[0]}:${parsedDurationInHours.toFixed(0).length === 1
                ? `0${parsedDurationInHours.toFixed(0)}`
                : parsedDurationInHours.toFixed(0)
              }min`
          );

          const distanceInMeters = data.routes[0].distance;
          const distanceInKilometers = (distanceInMeters / 1000).toFixed(2);
          setPassengerDistance(
            distanceInMeters <= 999
              ? `${distanceInMeters}metros`
              : `${distanceInKilometers}kms`
          )
        })
        .catch(err => {
          console.error(err);
        });
    } catch {
      setTime('Aconteceu um erro');
    }
    return `${passengerTime} (${passengerDistance})`;
  };

  useEffect(() => {
    getTripDistance();
  }, [])

  return (
    <Container {...rest}>
      {/* prettier-ignore */}
      <div className="nameContainer" >
        <CardHeader>
          <TripTotalValue>
            {price}
          </TripTotalValue>
          <TripValuesContainer>
            <TripValues>
              R${(((parseFloat(((parseFloat(((price).slice(3)).replace(/,/gi, ".")))))) / parseFloat(distanceTo.slice(0, distanceTo.length - 3))).toFixed(2))} Por km rodado
            </TripValues>
            <TripValues>
              R${((parseFloat(((parseFloat(((price).slice(3)).replace(/,/gi, ".")))))) / parseFloat(timeTo.slice(0, timeTo.length - 3))).toFixed(2)} Por min rodado
            </TripValues>
          </TripValuesContainer>
          <Seats>
            {seats}
          </Seats>
        </CardHeader>
        {passengers.map((passenger) => (
          <Route>
            <div className="divisorWrapper">
              <img src={Divisor} alt="Divisor" className="divisor" />
            </div>
            <RouteHeader>
              <Profile>
                <ProfileImage src={PassengerImage} />
                <ProfileInfo>
                  <ProfileName>{passenger.passengerName}</ProfileName>
                  <ProfileSince>Desde: {getCreatedDate(passenger.createdAt)}</ProfileSince>
                </ProfileInfo>
              </Profile>
              <HeaderRight>
                <HeaderRightItem>
                  <IoCashOutline size={20} color="#22f100" />
                  <HeaderItemText>
                    Dinheiro
                  </HeaderItemText>
                </HeaderRightItem>
                <HeaderRightItem>
                  <TiArrowBackOutline size={20} color="#000" />
                  <HeaderItemText>
                    Retorno
                  </HeaderItemText>
                </HeaderRightItem>
              </HeaderRight>
            </RouteHeader>
            <RouteInfoValues>{getPassengerCost(passenger.passengerId)} - {fetchTimePassenger({ lng: passenger.boardingAddress.longitude, lat: passenger.boardingAddress.latitude }, { lng: driverLng, lat: driverLat })} - {fetchTimePassenger({ lng: passenger.boardingAddress.longitude, lat: passenger.boardingAddress.latitude }, { lng: passenger.landingAddress.longitude, lat: passenger.landingAddress.latitude })} </RouteInfoValues>
            <RouteAddress>
              <Left>
                <PointedDivisor
                  src={PointedDivisorImg}
                  alt="Pointed divisor"
                />
                <RouteList>
                  <RouteItem>{passenger.boardingAddress.name}</RouteItem>
                  <RouteItem2>{passenger.landingAddress.name}</RouteItem2>
                </RouteList>
              </Left>
            </RouteAddress>
          </Route>
        ))}
      </div>
    </Container>
  );
}
