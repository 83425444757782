import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { NSmallButton, NTitle, NInput, NButton } from '~/components';
import { useApi, useAuth, useCountdown } from '~/hooks';
import { getDomain } from '~/utils';

import { Container } from './styles';

const Confirm = ({
  title,
  type,
  confirmService,
  sendService,
  createService,
  updateService,
}) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const { request } = useApi();

  const code = useRef();

  const [error, setError] = useState(false);
  const [value, setValue] = useState('');
  const [validate, setValidate] = useState(false);

  const { setUser } = useAuth();

  const onCreate = () =>
    request(
      () => createService(code.current.value),
      response => {
        navigate(`/${getDomain()}/cadastro/sucesso`, {
          state: { success: true, userId: response?.userId },
        });
      },
      () => {
        navigate(`/${getDomain()}/cadastro/telefone`, { state });

        return 'O código expirou, tente novamente!';
      }
    );

  const onUpdate = () =>
    request(
      () => updateService(code.current.value),
      response => {
        setUser(response.data);
        navigate(`/${getDomain()}/perfil`);
      },
      () => {
        navigate(`/${getDomain()}/cadastro/telefone`, { state });

        return 'O código expirou, tente novamente!';
      }
    );

  const onSubmit = () => {
    // if (type === 'SMS' && !updateService) {
    //   request(
    //     () => confirmService(code.current.value),
    //     () =>
    //       navigate('/'+ getDomain() + '/cadastro/email', {
    //         state: { ...state, phoneKey: code.current.value },
    //       }),
    //     () => setError(true)
    //   );
    // }
    if (!updateService) {
      request(
        () => confirmService(code.current.value),
        () => onCreate(code.current.value),
        () => setError(true)
      );
    } else {
      request(
        () => confirmService(code.current.value),
        () => onUpdate(code.current.value),
        () => setError(true)
      );
    }
  };

  const validateService = e => {
    e.preventDefault();
    if (type === 'SMS') {
      request(
        () => sendService(value.replace(/\D/g, '')),
        () => setValidate(true),
        () => setError(true)
      );
    } else {
      request(
        () => sendService(value),
        () => setValidate(true),
        () => setError(true)
      );
    }
  };

  const { timer, isCounting, toggleCounter } = useCountdown(60);

  return (
    <Container error={error} validate={validate}>
      <NTitle label={title} className="title" />
      {type === 'SMS' && <p className="subtitle">Digite o seu telefone:</p>}
      {type !== 'SMS' && <p className="subtitle">Digite o seu email:</p>}
      <form onSubmit={validateService} className="form">
        {type === 'SMS' && (
          <NInput
            value={value}
            required
            placeholder="Digite aqui..."
            onChange={e => setValue(e.target.value)}
            mask="55 (99) 99999-9999"
          />
        )}
        {type !== 'SMS' && (
          <NInput
            value={value}
            required
            placeholder="Digite aqui..."
            onChange={e => setValue(e.target.value)}
            type="email"
          />
        )}

        <NSmallButton
          type="submit"
          label={isCounting ? timer : 'Enviar código'}
          className="button_validate"
          onClick={e => {
            toggleCounter();
            validateService(e);
          }}
          disabled={isCounting}
        />
      </form>

      <p className="error">Código inválido ou telefone já utilizado!</p>

      {validate && (
        <div className="div_validation">
          <p>Digite o código de verificação:</p>
          <VerificationInput
            classNames={{
              container: 'input-container',
              character: 'input',
              characterSelected: 'input-selected',
              characterInactive: 'input-inactive',
            }}
            ref={code}
            onChange={() => setError(false)}
          />

          <NButton className="button" label="Confirmar" onClick={onSubmit} />
        </div>
      )}
    </Container>
  );
};

export default Confirm;
