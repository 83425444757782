import { useState } from 'react';
import { io } from 'socket.io-client';
import { getAuth } from '~/repositories/auth';

const useSocket = () => {
  const { accessToken, isPassenger } = getAuth();
  let socket;

  const [isConnected, setIsConnected] = useState(false);

  const createWebsocketClient = (
    // queryParams,
    callbackDisconnect = () => null,
    callbackConnect = () => null,
    callbackError = () => null
  ) => {
    const namespace = isPassenger ? 'passengers' : 'drivers';

    socket = io(`${process.env.REACT_APP_SOCKET_IO_URL}/${namespace}`, {
      // query: queryParams,
      auth: {
        accessToken,
      },
      transports: ['websocket'],
    });

    socket.on('connect', async () => {
      try {
        callbackConnect();
        if (socket.connected) setIsConnected(true);
        else setIsConnected(false);
      } catch (error) {
        console.log(error);
        callbackError(error);
      }
    });

    socket.on('connect_error', err => {
      console.log('Error on Socket Connection', err);
      console.log(err);
      callbackError(err);
    });

    socket.on('custom_error', err => {
      console.log('Custom Error', err);
      callbackError(err);
    });

    socket.on('connect_failed', err => {
      console.log('Socket Connection Failed', err);
      console.log(err);
      callbackError(err);
    });

    socket.on('disconnect', err => {
      console.log('Socket Disconnected', err);
      callbackDisconnect(err);
    });
    return socket;
  };

  const closeWebsocketClient = () => {
    socket.close();
    console.log('Close Connection', socket);
    if (socket.connected) setIsConnected(true);
    else setIsConnected(false);
  };

  const listenEvent = ({ channel, onReceived }) => {
    socket.on(channel, payload => {
      onReceived(payload);
      console.log('Successful Receipt:', channel);
    });
  };

  const muteEvent = ({ channel }) => {
    socket.removeAllListeners(channel);
  };

  const emitEvent = ({ channel, sendable }) => {
    socket.emit(channel, sendable);
  };

  return {
    createWebsocketClient,
    isConnected,
    listenEvent,
    muteEvent,
    emitEvent,
    closeWebsocketClient,
    socket,
  };
};

export default useSocket;
