import axios from 'axios';

import { getAppId } from '~/repositories/app';
import {
  getAuth,
  getRefreshToken,
  removeAuth,
  setAuth,
} from '~/repositories/auth';

const { REACT_APP_API_URL: baseURL } = process.env;

const API_V1 = '/api/v1';

const URL_V1 = `${baseURL}${API_V1}`;

const accessTokenInterceptor = config => {
  const appId = getAppId();
  const { accessToken } = getAuth();

  config.headers.Authorization = `Bearer ${accessToken}`;

  config.baseURL = `${config.baseURL}/${appId}`;

  return config;
};

const refreshTokenInterceptor = api => async error => {
  const refreshToken = getRefreshToken();

  const { response, config } = error;

  if (response.status !== 403 || !refreshToken) {
    throw error;
  }

  try {
    const { data } = await api.post('/auth/refresh', { refreshToken });

    setAuth({ ...data, isPassenger: data.accountType === 'passenger' });

    return await api.request({ ...config, baseURL: api.baseURL });
  } catch {
    removeAuth();

    window.location.reload();

    throw error;
  }
};

export const duplicateUser = async ({ userId, appId, accountType }) => {
  try {
    const { data } = await axios.post(
      `${baseURL}/api/v1/admin/users/duplicate`,
      { userId, appId, accountType },
      {
        headers: {
          'X-Chevette-Key': 'c68686e2-d818-4abd-985a-29464426c69b',
        },
      }
    );

    return data; // Retorna a resposta da API
  } catch (error) {
    console.error('Erro ao duplicar usuário:', error);
    throw error; // Propaga o erro
  }
};

const base = axios.create({ baseURL: URL_V1 });

const admin = axios.create({
  baseURL: `${URL_V1}/admin`,
  headers: {
    'X-Chevette-Key': 'c68686e2-d818-4abd-985a-29464426c69b',
  },
});

const api = axios.create({
  baseURL: `${URL_V1}/apps`,
  headers: {
    'X-Chevette-Key': 'c68686e2-d818-4abd-985a-29464426c69b',
  },
});

const apiForm = axios.create({
  baseURL: `${URL_V1}/apps`,
  headers: {
    'X-Chevette-Key': 'c68686e2-d818-4abd-985a-29464426c69b',
    'Content-Type': 'multipart/form-data',
  },
});

api.interceptors.request.use(accessTokenInterceptor);

apiForm.interceptors.request.use(accessTokenInterceptor);

api.interceptors.response.use(
  response => response,
  refreshTokenInterceptor(api)
);

apiForm.interceptors.response.use(
  response => response,
  refreshTokenInterceptor(apiForm)
);

export { baseURL, base, admin, api, apiForm };
